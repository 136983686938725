export const MENU_TYPE = {
    DASHBOARD: "Dashboard",
    USER_MANAGEMENT: "User Management",
    ENTERPRISE_MANAGEMENT: "Enterprise Management",
    SERVICE_MANAGEMENT: "Service Management",
    SERVICE_PRICE_MANAGEMENT: "Service Price Management",
    BREED_MANAGEMENT: "Breed Management",
    BOOKIng_MANAGEMENT: "Booking Management",
};

export const ACTION_TYPE = {
    SEARCH: "Search",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
    VIEW: "View",
    CONFIRM: "Confirm",
};
